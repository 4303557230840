<script setup>
import { ref } from 'vue';
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Checkbox from '@/Components/Form/Checkbox.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import Banner from '@/Components/Banner.vue';
import imadisLogoUrl from '~/images/imadis_logo.svg';
import background1Url from '~/images/login-bg-cropped-1.jpg';
import background1WebpUrl from '~/images/login-bg-cropped-1.webp';
import background2Url from '~/images/login-bg-cropped-2.jpg';
import background2WebpUrl from '~/images/login-bg-cropped-2.webp';
import background3Url from '~/images/login-bg-cropped-3.jpg';
import background3WebpUrl from '~/images/login-bg-cropped-3.webp';

const bgRandom = Math.floor(Math.random() * 3);

const backgroundUrls = [background1Url, background2Url, background3Url];

const backgroundWebpUrls = [background1WebpUrl, background2WebpUrl, background3WebpUrl];

defineProps({
    status: String,
});

const appEnv = ref(usePage().props.appEnv);
const showPassword = ref(false);

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};

const toggleShow = () => {
    showPassword.value = !showPassword.value;
};
</script>

<template>
    <Head>
        <title>{{ $t('Log in') }}</title>
    </Head>

    <div
        v-if="appEnv !== 'production'"
        class="navbar is-fixed-top is-flex is-justify-content-center has-background-danger has-text-centered p-4"
    >
        <p class="has-text-white">
            <strong class="has-text-white">{{ $t('Warning') + '! ' + $t('This version is not the production') + '!' }}</strong>
            <span>{{ ' #' + appEnv }}</span>
        </p>
    </div>

    <section class="login-wrapper">
        <div class="login-content">
            <div class="login-content-inner">
                <div v-if="status" class="mb-5 notification is-success is-light">
                    {{ status }}
                </div>

                <form @submit.prevent="submit" class="login-content__form">
                    <div class="login-content__branding">
                        <img :src="imadisLogoUrl" class="login-content__logo" alt="Logo" width="200" height="37" loading="lazy" />
                    </div>

                    <Banner />

                    <h1>{{ $t('Log in') }}</h1>

                    <div class="field">
                        <InputLabel for="email" value="Email" />
                        <div class="control has-icons-left">
                            <TextInput
                                id="email"
                                v-model="form.email"
                                type="email"
                                required
                                autofocus
                                autocomplete="username"
                                placeholder="example@imadis.fr"
                                class="is-rounded"
                            />
                            <span class="icon is-small is-left">
                                <font-awesome-icon icon="fa-solid fa-user" />
                            </span>
                        </div>
                        <InputError class="mt-2" :message="form.errors.email" />
                        <InputError class="mt-2" :message="form.errors.inactive" />
                    </div>

                    <div class="field">
                        <InputLabel for="password" :value="$t('Password')" />

                        <div class="field has-addons">
                            <div class="control has-icons-left is-fullwidth">
                                <TextInput
                                    v-if="!showPassword"
                                    id="password"
                                    v-model="form.password"
                                    type="password"
                                    required
                                    autocomplete="current-password"
                                    class="is-rounded"
                                />
                                <TextInput
                                    v-else
                                    id="password"
                                    v-model="form.password"
                                    type="text"
                                    required
                                    autocomplete="current-password"
                                    class="is-rounded"
                                />

                                <span class="icon is-small is-left">
                                    <font-awesome-icon icon="fa-solid fa-lock" />
                                </span>
                            </div>
                            <div class="control">
                                <button class="button is-rounded" :style="'height:100%;'" @click="toggleShow" type="button">
                                    <span class="icon is-small is-right">
                                        <font-awesome-icon v-if="!showPassword" icon="fa-solid fa-eye" />
                                        <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                                    </span>
                                </button>
                            </div>
                        </div>

                        <InputError class="mt-2" :message="form.errors.password" />
                    </div>

                    <div class="field mb-5">
                        <Checkbox v-model:checked="form.remember" name="remember" id="remember" class="is-checkradio" />
                        <label class="checkbox" for="remember">
                            {{ $t('Remember me') }}
                        </label>
                    </div>

                    <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between">
                        <PrimaryButton class="is-rounded is-fullwidth mb-5" :disabled="form.processing">
                            {{ $t('Log in') }}
                        </PrimaryButton>

                        <Link :href="route('password.request')" class="is-underlined">
                            {{ $t('Password forgotten ?') }}
                        </Link>
                    </div>

                    <div class="mt-5 is-flex is-flex-direction-column is-align-items-center">
                        <p class="mb-2">{{ $t('OR') }}</p>
                        <a :href="route('azureRedirect')" class="button is-blue is-rounded is-fullwidth">
                            <font-awesome-icon icon="fa-brands fa-windows" class="mr-2 is-size-5" />
                            {{ $t('Log in with Microsoft') }}
                        </a>
                    </div>
                </form>
            </div>
        </div>

        <div class="login-background">
            <picture>
                <source :srcset="backgroundWebpUrls[bgRandom]" type="image/webp" />
                <source :srcset="backgroundUrls[bgRandom]" type="image/jpg" />
                <img :src="backgroundUrls[bgRandom]" alt="Bienvenue sur IMADIS Tools" width="830" height="832" loading="lazy" />
            </picture>
        </div>
    </section>
</template>
